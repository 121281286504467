<template>
  <div class="reports-page-datatable">
    <el-table :data="data" style="width: 100%; font-size: 11px; padding: 0;" :max-height="screenHeight" class="el-table--mini" show-summary>
      <el-table-column sortable prop="branch_name" label="Filial" width="150" />
      <el-table-column sortable prop="truck_id" label="Placa" width="150" />
      <el-table-column sortable prop="deliveredkm" label="Distância Percorrida" width="150" />
      <el-table-column sortable prop="hours" label="Horas"/>
      <el-table-column sortable prop="truckCost" label="Custo Anual"/>
      <el-table-column sortable prop="cost_bykm" label="Custo por KM"/>
    </el-table>
  </div>
</template>

<script>
import exportJson from '@/helpers/exportXLSX'

export default {
  name: 'report-table-stats-by-truck',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Object,
      default: () => {
        return {
          veiculo: 'truck_id',
          filial: 'branch_name',
          filial_id: 'branch_id',
          horas_em_entrega: 'hours',
          km_percorrido: 'deliveredkm',
          truck_cost: 'truckCost',
          cost_bykm: 'cost_bykm',
        }
      },
    },
  },
  data() {
    return {}
  },
  computed: {
    screenHeight() {
      return (window.innerHeight -260)
    }
  },
  watch: {},
  mounted() {
    this.$emit('loaded')
  },
  methods: {
    DownloadXLS() {
      exportJson.setConfig({ debug: true })
      exportJson.DownloadXLS(this.data, this.fields)
    },
    DownloadCSV() {
      exportJson.DownloadCSV(this.data, this.fields)
    },
  },
}
</script>
